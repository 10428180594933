import React from "react";
import logo from "./logo.svg";
import s from "./app.module.css";
import { Link } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import HomePage from "./home";
import "./index.css";
import AboutPage from "./about";
import LocationPage from "./location";
import SolutionPage from "./solution";
import SustainPage from "./sustain";
import ContactPage from "./contact";
import TeamPage from "./team";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import logoIcon from "./imgs/logo.png";
import emailIcon from "./imgs/email.png";

function Content() {
  const [showNav, setShowNav] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isHome = window.location.pathname === "/";
  const isAbout =
    window.location.pathname && window.location.pathname.indexOf("about") >= 0;
  const isTeam =
    window.location.pathname && window.location.pathname.indexOf("team") >= 0;
  const isLocation =
    window.location.pathname &&
    window.location.pathname.indexOf("location") >= 0;

  const isSolution =
    window.location.pathname &&
    window.location.pathname.indexOf("solution") >= 0;

  const isSustain =
    window.location.pathname &&
    window.location.pathname.indexOf("sustain") >= 0;

  return (
    <div className={s.wrap}>
      <div className={s.header}>
        <div className={s.headerInner}>
          <img src={logoIcon} />
          <div
            className={s.navMobile}
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            <span />
            <span />
            <span />
          </div>
          <div className={s.mNav}>
            <div
              className={
                showNav
                  ? `${s.navMobileWrap} ${s.navMobileWrapShow}`
                  : s.navMobileWrap
              }
            >
              <span
                onClick={() => {
                  setShowNav(false);
                  navigate("/");
                }}
                className={isHome ? s.active : ""}
              >
                Home Page
              </span>
              <span
                className={isAbout ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/about");
                }}
              >
                About Us
              </span>
              <span
                className={isTeam ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/team");
                }}
              >
                Management Team
              </span>
              <span
                className={isLocation ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/location");
                }}
              >
                Locations
              </span>
              <span
                className={isSolution ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/solution");
                }}
              >
                Solutions
              </span>
              <span
                className={isSustain ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/sustainability");
                }}
              >
                Sustainability
              </span>
              <span
                className={isSustain ? s.active : ""}
                onClick={() => {
                  setShowNav(false);
                  navigate("/Contact");
                }}
              >
                Contact
              </span>
            </div>
          </div>
          <div className={s.headerNavWrap}>
            <span
              onClick={() => {
                navigate("/");
              }}
              className={isHome ? s.active : ""}
            >
              Home Page
            </span>
            <span
              className={isAbout ? s.active : ""}
              onClick={() => {
                navigate("/about");
              }}
            >
              About Us
            </span>
            <span
              className={isTeam ? s.active : ""}
              onClick={() => {
                navigate("/team");
              }}
            >
              Management Team
            </span>
            <span
              className={isLocation ? s.active : ""}
              onClick={() => {
                navigate("/location");
              }}
            >
              Locations
            </span>
            <span
              className={isSolution ? s.active : ""}
              onClick={() => {
                navigate("/solution");
              }}
            >
              Solutions
            </span>
            <span
              className={isSustain ? s.active : ""}
              onClick={() => {
                navigate("/sustainability");
              }}
            >
              Sustainability
            </span>
            <div
              onClick={() => {
                navigate("/contact");
              }}
              className={s.contactWrap}
            >
              <img src={emailIcon} />
              <p>Contact</p>
            </div>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <TransitionGroup>
          <CSSTransition key={location.key} classNames="slide" timeout={2000}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/solution" element={<SolutionPage />} />
              <Route path="/sustainability" element={<SustainPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className={s.c6}>
        <div className={s.c6l}>
          <h3>Useful Links</h3>
          <div className={s.c6lrow}>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/");
              }}
            >
              <div className={s.c6lItemLabel}>Home Page</div>
            </div>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/about");
              }}
            >
              <div className={s.c6lItemLabel}>About Us</div>
              <div className={s.c6lItemValue}></div>
            </div>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/team");
              }}
            >
              <div className={s.c6lItemLabel}>Management Team</div>
              <div className={s.c6lItemValue}></div>
            </div>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/location");
              }}
            >
              <div className={s.c6lItemLabel}>Locations</div>
              <div className={s.c6lItemValue}></div>
            </div>
          </div>
          <div className={s.c6lrow}>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/solution");
              }}
            >
              <div className={s.c6lItemLabel}>Solutions</div>
              <div className={s.c6lItemValue}></div>
            </div>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/sustainability");
              }}
            >
              <div className={s.c6lItemLabel}>Sustainability</div>
            </div>
            <div
              className={s.c6lrowItem}
              onClick={() => {
                navigate("/contact");
              }}
            >
              <div className={s.c6lItemLabel}>Contact Us</div>
              <div className={s.c6lItemValue}></div>
            </div>
          </div>
        </div>
        <div className={s.c6r}>
          <div>
            <img src={logoIcon} />
          </div>
          <div className={s.c6rContent}>
            <p>
              For any business inquiries or feedback, please leave us a message
              here.
            </p>
            <p>We will get back to you as soon as possible.</p>
            <p>
              <a href="mailto:support@grandtechnik.com">
                support@grandtechnik.com
              </a>
            </p>
            <p>9 Raffles Place #35-05 Republic Plaza, Singapore 048619</p>
          </div>
        </div>
      </div>
      <div className={s.footer}>
        Copyright © GrandTechnik Pte Ltd. All rights reserved.
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Content />
    </BrowserRouter>
  );
}

export default App;
