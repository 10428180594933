import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import u1Icon from "./imgs/u1.png";
//mailto:support@grandtechnik.com?subject=Testing out mailto!&body=This is only a test!
function Page() {
  const [name, setName] = React.useState("");
  const [content, setContent] = React.useState("");
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>Contact Us</h2>
        </div>
        <div className={s.c2}>
          <h3>Contact Us</h3>
        </div>
        <div className={s.c3}>
          <div className={s.c3l}>
            <p>
              For any business inquiries or feedback, please leave us a message
              here.
            </p>
            <p>We will get back to you as soon as possible.</p>
            <p>
              <a href="mailto:support@grandtechnik.com">
                support@grandtechnik.com
              </a>
            </p>
            <p>9 Raffles Place #35-05 Republic Plaza, Singapore 048619</p>
          </div>
          <div className={s.c3r}>
            <div className={s.c3row}>
              <div className={s.c3rItem}>
                <div className={s.c3label}>Name</div>
                <div className={s.c3Value}>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={s.c3rowFull}>
              <div className={s.c3label}>Message</div>
              <div className={s.c3Value}>
                <textarea
                  onChange={(e) => {
                    setContent(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={s.c3rowButton}>
              <a
                href={`mailto:support@grandtechnik.com?subject=Business Inquiries from ${name}&body=${content}`}
                className={!name || !content ? s.disable : s.normal}
              >
                Submit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
