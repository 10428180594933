import React from "react";
import s from "./s.module.css";
import { useParams, useNavigate } from "react-router-dom";
import logoIcon from "./imgs/logo.png";
import emailIcon from "./imgs/email.png";
import bg1Icon from "./imgs/bg1.png";
import bg2Icon from "./imgs/bg2.png";
import sbg1Icon from "./imgs/sbg1.png";
import sbg2Icon from "./imgs/sbg2.png";
import sbg3Icon from "./imgs/sbg3.png";
import mapIcon from "./imgs/map.png";
import positionIcon from "./imgs/position.png";
import icon1 from "./imgs/icon1.png";
import icon2 from "./imgs/icon2.png";
import icon3 from "./imgs/icon3.png";
//https://www.cryptofinance.ch/en/
function Page() {
  let navigate = useNavigate();
  const [an1, setAn1] = React.useState("");
  const [an2, setAn2] = React.useState("");

  React.useEffect(() => {
    setAn1(s.play);
    setTimeout(() => {
      setAn2(s.play2);
    }, 500);
    setTimeout(() => {
      setAn1(`${s.play} ${s.playDone}`);
    }, 2000);
    setTimeout(() => {
      setAn2(`${s.play2} ${s.play2Done}`);
    }, 4000);
  }, []);

  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>
            <p className={an1}>
              We <span style={{ color: "#27af2a" }}> save</span> energy & make
            </p>
            <p className={an2}> the planet greener</p>
          </h2>
        </div>
      </div>
      <div className={s.c2}>
        <div className={s.c21}>
          Grandtechnik is a developer & operator of powerful, effective and
          environmentally-friendly data centers.
        </div>
        <div className={s.c22}>
          We are a team of energy and data experts, connecting data with 100% of
          green energy to the future.
        </div>

        <div className={s.c23}>
          Grandtechnik is an engineering, technical consulting and project
          management company providing end-to-end clean energy data center
          solution.
        </div>
      </div>
      <div className={s.c3}>
        <div className={s.c3info}>
          <h3>Our Strengths</h3>
          <ul>
            <li>
              Professional team with lots of experience in data centers
              feasibility studies, development, design and engineering.
            </li>
            <li>
              Partnered up with several local suppliers, builders and
              contractors to execute projects quickly & feasibly.
            </li>
            <li>
              Strong support from local municipalities, data centers investment
              facilitators & users of excess heat water.
            </li>
            <li>
              Deep understanding of the Norwegian systems such as the
              government, power companies and relevant regulations.
            </li>
          </ul>
        </div>
        <div className={s.map}>
          <img src={positionIcon} className={s.c3p1} />
          <img src={positionIcon} className={s.c3p2} />
          <img src={positionIcon} className={s.c3p3} />
          <img src={positionIcon} className={s.c3p4} />
        </div>

        <div className={s.mapMobile}>
          <img src={mapIcon} />
        </div>
      </div>
      <div className={s.c4}>
        <h2>Global Reach</h2>
        <div className={s.c4SubTitle}>
          Our reach goes beyond Singapore with business in Asia (China) and
          Europe (Norway)
        </div>
        <div className={s.c4nav}>
          <div className={s.c4navItem}>
            <img src={icon1}></img>
            <div className={s.c4ItemInfo}>
              <p className={s.c4ItemInfoTop}>4</p>
              <p className={s.c4ItemInfoBottom}>Citys</p>
            </div>
          </div>
          <div className={s.c4navItem}>
            <img src={icon2}></img>
            <div className={s.c4ItemInfo}>
              <p className={s.c4ItemInfoTop}>3</p>
              <p className={s.c4ItemInfoBottom}>Countries</p>
            </div>
          </div>
          <div className={s.c4navItem}>
            <img src={icon3}></img>
            <div className={s.c4ItemInfo}>
              <p className={s.c4ItemInfoTop}>100MV</p>
              <p className={s.c4ItemInfoBottom}>Capacity</p>
            </div>
          </div>
        </div>
      </div>
      <div className={s.c5}>
        <img src={bg1Icon} />
      </div>
    </div>
  );
}

export default Page;
