import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import infoIcon1 from "./imgs/p1.png";
import infoIcon2 from "./imgs/p2.png";
import infoIcon3 from "./imgs/p3.png";
import infoIcon4 from "./imgs/p4.png";
import infoIcon5 from "./imgs/p5.png";
import infoIcon6 from "./imgs/p6.png";

function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>Locations</h2>
        </div>
        <div className={s.c2}>
          <h3>Locations</h3>
          <h5>
            We partner you in Global Expansion Quality data centers colocation
            solutions in strategic, high-growth markets
          </h5>
        </div>
        <div className={s.c3}>
          <div className={s.c3ItemRow}>
            <div className={s.c3Item}>
              <div className={s.itemTitle}>Project Reference 1</div>
              <div className={s.itemSubTitle}>In Asia</div>
              <div className={s.itemImg}>
                <img src={infoIcon1} />
              </div>
              <ul className={s.itemContent}>
                <li>
                  Free Air Cooling 20MW Modular Solution with raised up on the
                  nature grass , became operational in November 2019
                </li>
              </ul>
            </div>
            <div className={s.c3Item}>
              <div className={s.itemTitle}>Project Reference 2</div>
              <div className={s.itemSubTitle}>Data Center in Asia</div>
              <div className={s.itemImg}>
                <img src={infoIcon2} />
              </div>
              <ul className={s.itemContent}>
                <li>
                  Integrated water cooling solution, patented in March 2020 and
                  became operational in December 2020
                </li>
              </ul>
            </div>
            <div className={s.c3Item}>
              <div className={s.itemTitle}>Project Reference 3</div>
              <div className={s.itemSubTitle}>In Singapore</div>
              <div className={s.itemImg}>
                <img src={infoIcon3} />
              </div>
              <ul className={s.itemContent}>
                <li>Client project</li>
              </ul>
            </div>
          </div>

          <div className={s.c3ItemRow}>
            <div className={s.c3Item}>
              <div className={s.itemTitle}>Project Reference 4</div>
              <div className={s.itemSubTitle}>In Norway（Developing）</div>
              <div className={s.itemImg}>
                <img src={infoIcon4} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
