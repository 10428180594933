import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import infoIcon1 from "./imgs/icon1.png";
import infoIcon2 from "./imgs/icon2.png";
import infoIcon3 from "./imgs/icon3.png";
import infoIcon4 from "./imgs/icon4.png";
import infoIcon5 from "./imgs/icon5.png";
import infoIcon6 from "./imgs/icon6.png";
import infoIcon7 from "./imgs/icon7.png";
import infoIcon8 from "./imgs/icon8.png";
import infoIcon9 from "./imgs/icon9.png";
import infoIcon10 from "./imgs/icon10.png";
function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>About Us</h2>
        </div>
      </div>
      <div className={s.c2}>
        <div className={s.c2Item}>
          <div className={s.c2Left}>
            <div className={s.c2Title}>
              <h3>Our Manifesto</h3>
              <p>Leading the future</p>
            </div>
            <p>
              In today’s connected world, data centers are mission-critical to
              your organization. You need a partner that has deep expertise
              throughout your data center’s lifecycle. A partner that can power
              outcomes today – for tomorrow.
            </p>
            <p>
              Grandtechnik aims to build green, smart, sustainable, and reliable
              low-carbon data centers through the reconstruction of four key
              areas: architecture, cooling systems, Operations and Maintenance
              (O&M), and power supply.
            </p>
            <p>
              GT has focused on innovations that help to save energy and reduce
              power consumption, to achieve higher efficiency for data centers.
            </p>
          </div>
          <div className={s.c2Right}>
            <img src={infoIcon1} />
          </div>
        </div>
        <div className={s.c2Item}>
          <div className={s.c2Left}>
            <img src={infoIcon2} />
          </div>
          <div className={s.c2Right}>
            <div className={s.c2Title}>
              <h3>About Us</h3>
            </div>
            <p>
              Grandtechnik (GT) was founded 2018 in Singapore. Since
              incorporation, the company expanded rapidly and executed IDC
              projects for Google and Microsoft Data Centers. At the same time,
              GT also provides services such as IDC site selection, feasibility
              study and due diligence check for IDC development. We had set up
              two High Performance Computing and AI Computing IDC in Asia. Our
              other area of expertise is Cloud Computing. In 2021, we decided to
              expand our IDC to Norway.
            </p>
            <p>
              Our Company successfully received the investment from Singapore
              government fund Enterprise Singapore (ESG) and form strategic
              partnership with several market leaders.
            </p>
          </div>
        </div>
      </div>
      <div className={s.c3}>
        <h3>Company Timeline</h3>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft}>
            <img src={infoIcon3} />
          </div>
          <div className={s.c3ItemRight} style={{ background: "#F6F6F6" }}>
            <div className={s.c3Info}>
              <div className={s.c3InfoTitle}>2018</div>
              <div className={s.c3InfoContent}>
                <p>Received ESG</p>
                <p>Funding</p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft} style={{ background: "#59C05B" }}>
            <div className={s.c3InfoLeft}>
              <div className={s.c3InfoTitle} style={{ color: "#fff" }}>
                2019.6
              </div>
              <div className={s.c3InfoContent}>
                <p style={{ color: "#fff" }}>1st Project</p>
                <p style={{ color: "#fff" }}>Delivery</p>
              </div>
            </div>
          </div>
          <div className={s.c3ItemRight}>
            <img src={infoIcon4} />
          </div>
        </div>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft}>
            <img src={infoIcon5} />
          </div>
          <div className={s.c3ItemRight} style={{ background: "#F6F6F6" }}>
            <div className={s.c3Info}>
              <div className={s.c3InfoTitle}>2019.9</div>
              <div className={s.c3InfoContent}>
                <p>Received ESG</p>
                <p>Funding</p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft} style={{ background: "#F6F6F6" }}>
            <div className={s.c3InfoLeft}>
              <div className={s.c3InfoTitle}>2019.11</div>
              <div className={s.c3InfoContent}>
                <p>1st Project</p>
                <p>Delivery</p>
              </div>
            </div>
          </div>
          <div className={s.c3ItemRight}>
            <img src={infoIcon6} />
          </div>
        </div>

        <div className={s.c3Item}>
          <div className={s.c3ItemLeft}>
            <img src={infoIcon7} />
          </div>
          <div className={s.c3ItemRight} style={{ background: "#F6F6F6" }}>
            <div className={s.c3Info}>
              <div className={s.c3InfoTitle}>2020.3</div>
              <div className={s.c3InfoContent}>
                <p>Granted Rack IP</p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft} style={{ background: "#F6F6F6" }}>
            <div className={s.c3InfoLeft}>
              <div className={s.c3InfoTitle}>2020.8</div>
              <div className={s.c3InfoContent}>
                <p>Cloud</p>
                <p>Computing</p>
              </div>
            </div>
          </div>
          <div className={s.c3ItemRight}>
            <img src={infoIcon8} />
          </div>
        </div>

        <div className={s.c3Item}>
          <div className={s.c3ItemLeft}>
            <img src={infoIcon9} />
          </div>
          <div className={s.c3ItemRight} style={{ background: "#F6F6F6" }}>
            <div className={s.c3Info}>
              <div className={s.c3InfoTitle}>2020.11</div>
              <div className={s.c3InfoContent}>
                <p>Nordic DC</p>
              </div>
            </div>
          </div>
        </div>
        <div className={s.c3Item}>
          <div className={s.c3ItemLeft} style={{ background: "#F6F6F6" }}>
            <div className={s.c3InfoLeft}>
              <div className={s.c3InfoTitle}>2021.7</div>
              <div className={s.c3InfoContent}>
                <p>Krefter.no</p>
              </div>
            </div>
          </div>
          <div className={s.c3ItemRight}>
            <img src={infoIcon10} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
