import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import icon1 from "./imgs/icon1.png";
import icon2 from "./imgs/icon2.png";
import icon3 from "./imgs/icon3.png";
import s1 from "./imgs/s1.png";
import s2 from "./imgs/s2.png";
import s3 from "./imgs/s3.png";
import s4 from "./imgs/s4.png";
function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>Solutions</h2>
        </div>
        <div className={s.c2}>
          <h3>Solutions</h3>
          <h5>
            Our Data centers Solutions Our customers are at the forefront of
            everything that we do
          </h5>
          <p>
            With people flocking towards remote working and education, along
            with an influx of smart technologies inside offices and remote
            locations – data center frontier is at the edge. In this age of
            data, there are currently about seven billion Internet-connected
            devices, and that number continues to grow. Many of these generate
            large masses of data that must be captured, routed, stored,
            evaluated, and retrieved. With the rise of the Internet of Things
            (IoT) and Industry 4.0, manufacturers are relying on big data and
            data analytics to enhance the efficiency, productivity, security,
            and cost-effectiveness of their operations.
          </p>
          <p>
            To save energy and infrastructure costs, we are the solution to you!
          </p>
          <p>
            We provide physical space, power, and cooling systems for servers
            and connections to local communication networks.
          </p>
        </div>
        <div className={s.c3}>
          <div className={s.c3ItemRow}>
            <div className={s.c3Item}>
              <div className={s.c3Img}>
                <img src={s1} />
              </div>
              <div className={s.c3Content}>Enterprise data centers</div>
            </div>
            <div className={s.c3Item}>
              <div className={s.c3Img}>
                <img src={s2} />
              </div>
              <div className={s.c3Content}>Managed services data centers</div>
            </div>
            <div className={s.c3Item}>
              <div className={s.c3Img}>
                <img src={s3} />
              </div>
              <div className={s.c3Content}>Colocation data centers</div>
            </div>
            <div className={s.c3Item}>
              <div className={s.c3Img}>
                <img src={s4} />
              </div>
              <div className={s.c3Content}>Cloud data centers</div>
            </div>
          </div>
        </div>

        <div className={s.c4}>
          <div className={s.c4ItemRow}>
            <div className={s.c4Item}>
              <div className={s.c4Img}>
                <img src={icon1} />
              </div>
              <ul className={s.c4ontent}>
                <li>
                  Lower energy cost thanks to the measuring equipment,
                  visualization software, selective switching and building
                  control and regulation
                </li>
                <li>
                  Optimized energy consumption by metering and monitoring of
                  power and cooling systems
                </li>
                <li>
                  Reduced carbon dioxide emissions through efficient power
                  distribution
                </li>
              </ul>
            </div>
            <div className={s.c4Item}>
              <div className={s.c4Img}>
                <img src={icon2} />
              </div>
              <ul className={s.c4ontent}>
                <li>Minimize cold and hot air mixing</li>
                <li>
                  Differential pressure across the aisle ensures sufficient
                  cooling
                </li>
                <li>
                  Accurate space temperature sensors ensure energy-efficient
                  cooling
                </li>
              </ul>
            </div>
            <div className={s.c4Item}>
              <div className={s.c4Img}>
                <img src={icon3} />
              </div>
              <ul className={s.c4ontent}>
                <li>
                  Cost Savings due to minimize operational cost and reduced
                  total floor space Improve "GREEN" Reputation due to reduced
                  carbon footprint
                </li>
                <li>
                  Energy Savings due to utilization of renewable energy sources
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
