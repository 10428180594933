import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import u1Icon from "./imgs/u1.png";
function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>Management Team</h2>
        </div>
        <div className={s.c2}>
          <h3>Our Team Members</h3>
          <h5>
            Our senior management works closely with our leadership teams to
            steer the company towards achieving sustainable growth.
          </h5>
        </div>
        <div className={s.c3}>
          <div className={s.c3ItemRow}>
            <div className={s.c3Item}>
              <div className={s.c3ItemImg}>
                <img src={u1Icon} />
              </div>
              <div className={s.c3Content}>
                <div className={s.c3Name}>William Qiu</div>
                <div className={s.c3Position}>Equity Partner</div>
                <div className={s.c3Info}>
                  <p>
                    Studied MBA at University of Manchester, Bachelor at NTU in
                    Material Science. Worked in Nokia Bell Lab and Nokia ICT
                    strategic center for global data centers development
                    planning and feasibility Study.
                  </p>
                </div>
              </div>
            </div>
            <div className={s.c3Item}>
              <div className={s.c3ItemImg}>
                <img src={u1Icon} />
              </div>
              <div className={s.c3Content}>
                <div className={s.c3Name}>Greg Chen</div>
                <div className={s.c3Position}>Equity Partner</div>
                <div className={s.c3Info}>
                  <p>
                    Bachelor in Mechanical Engineering from National University
                    of Singapore. Worked with Siemens, Google & Microsoft, led
                    several data centers design and development projects.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className={s.c3Item}>
              <div className={s.c3ItemImg}>
                <img src={u1Icon} />
              </div>
              <div className={s.c3Content}>
                <div className={s.c3Name}>Oliver Horvei</div>
                <div className={s.c3Position}>Equity Partner</div>
                <div className={s.c3Info}>
                  <p>
                    Studied BBA in UoL, founded Lona, a BioTech big data company
                    developing novel TCR-T therapies for treating leukemia, &
                    Anycareer, an AI platform for automated, personalised
                    education & recruitment.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
          <div className={s.c3ItemRow}>
            {/* <div className={s.c3Item}>
              <div className={s.c3ItemImg}>
                <img src={u1Icon} />
              </div>
              <div className={s.c3Content}>
                <div className={s.c3Name}>Greg Chen</div>
                <div className={s.c3Position}>Equity Partner</div>
                <div className={s.c3Info}>
                  <p>
                    Bachelor in Mechanical Engineering from National University
                    of Singapore. Worked with Siemens, Google & Microsoft, led
                    several data centers design and development projects.
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className={s.c3Item}>
              <div className={s.c3ItemImg}>
                <img src={u1Icon} />
              </div>
              <div className={s.c3Content}>
                <div className={s.c3Name}>Peter Wu</div>
                <div className={s.c3Position}>Equity Partner</div>
                <div className={s.c3Info}>
                  <p>
                    Director of land acquisition team to provide professional
                    real estate service to clients in South East Asia.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
