import React from "react";
import s from "./s.module.css";
import bg1Icon from "./imgs/bg1.png";
import bg2Icon from "./imgs/bg2.png";
import icon1 from "./imgs/icon1.png";
import icon2 from "./imgs/icon2.png";
import icon3 from "./imgs/icon3.png";
import icon4 from "./imgs/icon4.png";
import icon5 from "./imgs/icon5.png";
import icon6 from "./imgs/icon6.png";
function Page() {
  return (
    <div className={s.wrap}>
      <div className={s.c1}>
        <div className={s.c1bg} />
        <div className={s.c1c}>
          <h2>Sustainability</h2>
        </div>
        <div className={s.c2}>
          <h3>Sustainability</h3>
          <h5>
            We are your Global Trusted Partner for a Sustainable Digital Future
          </h5>
        </div>

        <div className={s.c6}>
          <div className={s.c6ItemRow}>
            <div className={s.c6Item}>
              <div className={s.c6Img}>
                <img src={icon1} />
              </div>
              <div className={s.c6ontent}>
                <h5>Environmental Stewardship</h5>
                <p>
                  Data Centers is dedicated to ecologically sustainable business
                  practices, with an emphasis on improving energy efficiency,
                  lowering our carbon footprint, and minimizing waste and water
                  use. We can enhance resource efficiency, cut costs, and assist
                  the global climate change agenda by taking proactive measures
                  to control our environmental effect.
                </p>
                <p>
                  Our data centers have been certified to meet stringent
                  international energy management standards as well as the
                  Standard for Green Data Centers. In the creation of new
                  projects, we continue to use more sustainable building designs
                  and materials. We also make investments in energy-efficient
                  technology.
                </p>
              </div>
            </div>

            <div className={s.c6Item}>
              <div className={s.c6Img}>
                <img src={icon2} />
              </div>
              <div className={s.c6ontent}>
                <h5>Responsible Business</h5>
                <p>
                  We believe in long-term sustainability and will continue to
                  practice strong corporate governance to provide our clients
                  with transparency and long-term dependability.
                </p>
                <p>
                  It is also our responsibility, we believe, to drive innovation
                  in the development of greener solutions. Using the team's
                  strengths, we have invested in revolutionary transformative
                  innovations such as the Floating Data Centers Park and
                  High-rise data center, which aim to alleviate traditional data
                  center land, water, and energy constraints.
                </p>
              </div>
            </div>

            <div className={s.c6Item}>
              <div className={s.c6Img}>
                <img src={icon3} />
              </div>
              <div className={s.c6ontent}>
                <h5>People and Community</h5>
                <p>
                  As global citizens, we work closely with our partners to lead
                  initiatives to conserve biodiversity and promote climate
                  change counter measures with the goal of building a
                  sustainable future. Our global data center team regularly
                  volunteers to protect the environment, including planting
                  saplings, cleaning beaches, and building safe habitats for
                  bees.
                </p>
                <p>
                  Our integrated, end-to-end platform power data centers across
                  the globe. To help power your goals for net zero carbon
                  emissions and waste. With health and wellness and
                  sustainability at the forefront of many corporate agendas, we
                  are using more efficient systems, clean energy, and
                  carbon-cutting practices.
                </p>
                <p>
                  We are creating a greener industry and more sustainable world
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={s.c3}>
          <div className={s.c3Img}>
            <img src={bg2Icon} />
          </div>
          <div className={s.c3Info}>
            <p>
              Our integrated, end-to-end platform power data centers across the
              globe. To help power your goals for net zero carbon emissions and
              waste. With health and wellness and sustainability at the
              forefront of many corporate agendas, we are using more efficient
              systems, clean energy, and carbon-cutting practices.
            </p>
            <p>We are creating a greener industry and more sustainable world</p>
          </div>
        </div>

        <div className={s.c4}>
          <div className={s.c4ItemRow}>
            <div className={s.c4Item}>
              <div className={s.c4Info}>Green finance</div>
              <div className={s.c4Img}>
                <img src={icon4} />
              </div>
            </div>
            <div className={s.c4Item}>
              <div className={s.c4Info}>
                Creative designs and operational solutions
              </div>
              <div className={s.c4Img}>
                <img src={icon5} />
              </div>
            </div>
            <div className={s.c4Item}>
              <div className={s.c4Info}>
                Industry and government partnerships
              </div>
              <div className={s.c4Img}>
                <img src={icon6} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page;
